export const LANGUAGES = [
  { name: "English", flag: "gb", id: "en" },
  { name: "Polski", flag: "pl", id: "pl" },
  { name: "Español", flag: "es", id: "es" },
];

if (process.env.VUE_APP_ENV !== "production") {
  LANGUAGES.push({ name: "日本語", flag: "jp", id: "ja" });
}

// Touchpoint statuses
export const TOUCHPOINT_STATUS_PENDING = 1;
export const TOUCHPOINT_STATUS_FAILED = 2;
export const TOUCHPOINT_STATUS_APPROVED = 3;
export const TOUCHPOINT_STATUS_DECLINED = 4;
export const TOUCHPOINT_STATUS_APPROVED_INVOICED = 5;
export const TOUCHPOINT_STATUS_APPROVED_PAID_IN = 6;
export const TOUCHPOINT_STATUS_APPROVED_PAID_OUT = 7;
export const TOUCHPOINT_STATUS_DEBT_IN_COLLECTION = 8;
export const TOUCHPOINT_STATUS_DEBT_UNCOLLECTIBLE = 9;

export const CONVERSION_STATUS_AWAITING_CONFIRMATION = 1;
export const CONVERSION_STATUS_FAILED = 2;
export const CONVERSION_STATUS_CONFIRMED = 3;
export const CONVERSION_STATUS_DECLINED = 4;
export const CONVERSION_STATUS_WITHDRAWN = 5;
export const CONVERSION_STATUS_ON_HOLD = 6;

export const DATETIME_FORMAT = "YYYY-MM-DD";
export const DATETIME_FORMAT_TIME = "YYYY-MM-DD HH:mm";
export const DATETIME_FORMAT_TIME_SECONDS = "YYYY-MM-DD HH:mm:ss";
export const SHORT_DATE_FORMAT = "DD MMM";
export const DAY_AND_DATE_FORMAT = "ddd DD MMM";
export const DAY_AND_DATE_AND_HOUR_FORMAT = "ddd d MM h";
export const DAY_AND_DATE_AND_HOUR_AND_MINUTE_FORMAT = "ddd d mm HH:mm";
export const HOUR_DATE_FORMAT = "h";
export const HOUR_MINUTE_DATE_FORMAT = "HH:mm";

export const TRACKING_LINK_PARAMETERS = [
  "source_id",
  "sub_source_id",
  "click_id",
  "gaid",
  "idfa",
  "ref1",
  "ref2",
  "ref3",
  "ref4",
  "ref5",
];

export const BUTTON_TYPE = ["button", "submit", "reset"];
export const BUTTON_STATE = ["primary", "secondary", "danger", "dashed"];
export const INPUT_TYPE = [
  "text",
  "number",
  "password",
  "url",
  "email",
  "search",
];
