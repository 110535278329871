export const PERMISSIONS = {
  AccountEditAdInventory: ["square", "provider"],
  AccountEditBilling: ["square", "provider"],
  AccountEditBranding: ["square", "provider"],
  AccountEditCompany: ["square", "provider"],
  AccountEditLocalisation: ["square", "provider"],
  AccountEditManagement: ["square", "provider"],
  AccountEditPayments: ["square", "provider"],
  AccountEditUsers: ["square", "provider"],
  AdInventory: ["square", "provider", "advertiser"],
  AdvertiserAccounts: ["square", "provider"],
  BankAccounts: ["square", "provider"],
  Banners: ["square", "publisher", "provider", "advertiser"],
  ClicksReport: ["square", "provider", "advertiser", "publisher"],
  Dashboard: ["square", "provider", "advertiser", "publisher", "promoter"],
  DealsAndVouchers: ["square", "provider", "advertiser", "publisher"],
  DownloadCenter: ["square", "provider", "advertiser", "publisher"],
  Forbidden: ["square", "provider", "advertiser", "publisher", "promoter"],
  Invoices: ["square", "provider", "advertiser"],
  Marketplace: ["publisher"],
  NotFound: ["square", "provider", "advertiser", "publisher", "promoter"],
  Notifications: ["square", "provider", "advertiser", "publisher"],
  NotificationSettings: ["square", "provider", "advertiser", "publisher"],
  OfferCreator: ["square", "provider", "advertiser"],
  OfferCreatorNew: ["square", "provider", "advertiser"],
  OfferCreatorAddons: ["square", "provider", "advertiser"],
  OfferCreatorGeneral: ["square", "provider", "advertiser"],
  OfferCreatorTargeting: ["square", "provider", "advertiser"],
  OfferCreatorTerms: ["square", "provider", "advertiser"],
  OfferCreatorTracking: ["square", "provider", "advertiser"],
  OfferCreatorPriceSegments: ["square", "provider", "advertiser"],
  Offers: ["square", "provider", "advertiser"],
  PayoutsListing: ["square", "provider"],
  PerformanceReport: ["square", "provider", "advertiser", "publisher"],
  Postbacks: ["square", "provider", "publisher"],
  ProductFeeds: ["square", "provider", "advertiser", "publisher"],
  PromoterAccounts: ["square", "provider", "advertiser"],
  PromoterAccountInfo: ["square", "promoter"],
  PromoterPayoutsIncomplete: ["square","promoter"],
  ProviderAccounts: ["square"],
  PublisherAccounts: ["square", "provider"],
  RichMedia: ["square", "provider", "advertiser", "publisher"],
  SelfBilling: ["square", "provider", "publisher", "promoter"],
  SettingsAdInventory: ["square", "provider", "advertiser", "publisher"],
  SettingsBilling: ["square", "provider", "advertiser", "publisher", "promoter"],
  SettingsBranding: ["square", "provider", "advertiser", "publisher"],
  SettingsCompany: ["square", "provider", "advertiser", "publisher"],
  SettingsFinance: ["square", "provider"],
  SettingsLocalisation: ["square", "provider", "advertiser", "publisher"],
  SettingsPayments: ["square", "provider", "advertiser", "publisher", "promoter"],
  SettingsUsers: ["square", "provider", "advertiser", "publisher"],
  Signup: ["square", "provider", "advertiser", "publisher"],
  TextLinks: ["square", "provider", "advertiser", "publisher"],
  TouchpointsReport: ["square", "provider", "advertiser", "publisher", "promoter"],
  Trades: ["square", "provider", "advertiser"],
  TransactionsReport: ["square", "provider", "advertiser", "publisher"],
};

export const DV_ENTRY = {
  AdInventory: "publishers-connections",
  AdvertiserAccounts: "advertiser-accounts",
  Banners: "banners",
  ClicksReport: "clicks-report",
  DealsAndVouchers: "vouchers-and-deals",
  Invoices: "invoices",
  Marketplace: "publisher-marketplace",
  Offers: "offers-overview",
  PerformanceReport: "performance-report",
  Postbacks: "postbacks",
  ProductFeeds: "product-feeds",
  PromoterAccounts: "promoter-accounts",
  ProviderAccounts: "provider-accounts",
  PublisherAccounts: "publisher-accounts",
  RichMedia: "rich-media",
  SelfBilling: "self-billing",
  TextLinks: "text-links",
  TouchpointsReport: "touchpoints-report",
  Trades: "trades",
  TransactionsReport: "transactions-report",
};
