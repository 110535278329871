import AccountService from "@/services/account";

export default {
  state: {
    accountName: "",
    currency: {},
    currencies: [],
    changed: {},
    sessionInfo: {},
    currentUserEdit: false,
    passwordChangeOpen: false,
  },
  getters: {
    getAccountName: state => {
      return state.accountName;
    },
    getCurrency: state => {
      const currency = state.currency.id
        ? state.currency
        : JSON.parse(localStorage.getItem("active-currency"));
      return currency;
    },
    getCurrencies: state => {
      return state.currencies;
    },
    getCurrencyChanged: state => {
      return state.changed;
    },
    getSessionInfo: state => {
      return state.sessionInfo;
    },
    getCurrentUserEdit: state => {
      return state.currentUserEdit;
    },
    getPasswordChangeOpen: state => {
      return state.passwordChangeOpen;
    },
  },
  mutations: {
    changeCurrency: async (state, payload) => {
      try {
        await AccountService.changeCurrency(payload.id);
        state.currency = payload;
        state.changed = payload;
      } catch (err) {
        console.warn(err);
        state.currency = { id: "EUR", name: "Euro" };
      }
      localStorage.setItem("active-currency", JSON.stringify(state.currency));
    },
    setAccountName: (state, payload) => {
      state.accountName = payload;
    },
    setCurrency: (state, payload) => {
      state.currency = payload;
    },
    setSessionInfo: (state, payload) => {
      state.sessionInfo = payload;
    },
    setUserEdit: (state, payload) => {
      state.currentUserEdit = payload;
    },
    setCurrencies: (state, payload) => {
      state.currencies = payload;
    },
    setPasswordChangeOpen: (state, payload) => {
      state.passwordChangeOpen = payload;
    },
  },
  actions: {
    changeCurrency: ({ commit }, payload) => {
      commit("changeCurrency", payload);
    },
    setSessionInfo: ({ commit }, payload) => {
      commit("setSessionInfo", payload);
    },
    setAccountName: ({ commit }, payload) => {
      commit("setAccountName", payload);
    },
    setCurrency: ({ commit }, payload) => {
      commit("setCurrency", payload);
    },
    setUserEdit: ({ commit }, payload) => {
      commit("setUserEdit", payload);
    },
    setCurrencies: ({ commit }, payload) => {
      commit("setCurrencies", payload);
    },
    setPasswordChangeOpen: ({ commit }, payload) => {
      commit("setPasswordChangeOpen", payload);
    },
  },
};
