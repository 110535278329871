//window configuration passed down by backend twig template
export const ACCOUNT_ID = window.configuration.ACCOUNT_ID;
export const ACCOUNT_IS_ACTIVE = window.configuration.ACCOUNT_STATUS === 3; // 1: incomplete, 2: pending, 3: active, 4: closed
export const ACCOUNT_NAME = window.configuration.ACCOUNT_NAME;
export const ACCOUNT_STATUS = window.configuration.ACCOUNT_STATUS; // 1: incomplete, 2: pending, 3: active, 4: closed
export const ACCOUNT_TYPE = window.configuration.ACCOUNT_TYPE || "";
export const BACK_TO_ADMIN = window.configuration.BACK_TO_ADMIN;
export const CSRF_TOKEN = window.configuration.CSRF_TOKEN;
export const IS_SQUARE = window.configuration.ACCOUNT_PROVIDER === "1";
export const ACCOUNT_TYPE_SQUARE = ACCOUNT_TYPE === "provider" && IS_SQUARE ? "square" : ACCOUNT_TYPE;
export const PREVIOUS_ADMIN = window.configuration.PREVIOUS_ADMIN;
export const PROMOTER_AD_INVENTORY = window.configuration.PROMOTER_AD_INVENTORY;
export const PROMOTER_OFFER_NAME = window.configuration.PROMOTER_OFFER_NAME;
export const PROMOTER_OFFER_ID = window.configuration.PROMOTER_OFFER_ID;
export const PROVIDER_ID = window.configuration.PROVIDER_ID;
export const USER_CONFIRMED = !!window.configuration.USER_CONFIRMED; //value is 0 or 1
export const USER_ID = window.configuration.USER_ID;
export const IS_PROMOTER_DOMAIN = window.configuration.IS_PROMOTER_DOMAIN;

export let ABORT_CONTROLLER = new AbortController();
export const REFRESH_ABORT_CONTROLLER = () => {
  ABORT_CONTROLLER = new AbortController();
};
