import { ACCOUNT_TYPE } from "@/windowConfig";

import gettext from "@/gettext-compile.config";
const { $pgettext } = gettext;

const IS_PROMOTER = ACCOUNT_TYPE === "promoter";

export const DT_INVOICE = "INVOICE";
export const DT_INVOICE_CORRECTION = "INVOICE_CORRECTION";
export const DT_SELF_BILLING = "CREDIT_NOTE";
export const DT_SELF_BILLING_CORRECTION = "SELF_BILLING_NOTE"; // TODO: ask backend for refactor

export const DS_PRO_FORMA = "PRO_FORMA";
export const DS_PAID = "PAID";
export const DS_SENT = "SENT";
export const DS_OVERDUE = "OVERDUE";
export const DS_UNCOLLECTIBLE = "UNCOLLECTIBLE";
export const DS_DEBT_COLLECTION = "DEBT_COLLECTION";

export const getPageTitle = () => ({
  selfBilling: $pgettext("sidebar", "Self-Billings"),
  payments: $pgettext("sidebar", "Payouts"),
  invoices: $pgettext("sidebar", "Invoices"),
});

// For backward compatibility
export const PAGE_TITLE = getPageTitle();

export const getColumns = () => [
  {
    name: $pgettext("column name", "Status"),
    slug: "status",
    component: "VSVGStatusIcon",
    sortable: true,
    selected: true, 
  },
  {
    name: $pgettext("column name", "Document type"),
    slug: "type",
    sortable: true,
    selected: true,
  },
  {
    name: $pgettext("column name", "Sender"),
    slug: "sender",
    disallow: ["advertiser", "publisher", "promoter"],
    sortable: true,
    selected: true,
  },
  {
    name: $pgettext("column name", "Recipient type"),
    slug: "recipientType",
    disallow: ["publisher", "promoter"],
    sortable: true,
    selected: true,
  },
  {
    name: $pgettext("column name", "Recipient name"),
    slug: "recipientName",
    sortable: true,
    selected: true,
  },
  {
    name: $pgettext("column name", "Recipient sID"),
    slug: "recipientId",
    sortable: false,
    selected: true,
  },
  {
    name: IS_PROMOTER
      ? $pgettext("column name", "Document number")
      : $pgettext("column name", "Billing number"),
    slug: "number",
    sortable: true,
    selected: true,
  },
  {
    name: $pgettext("column name", "Issue date"),
    slug: "issueDate",
    sortable: true,
    selected: true,
  },
  {
    name: $pgettext("column name", "Due date"),
    slug: "dueDate",
    sortable: true,
    selected: true,
    invoiceOnly: true,
  },
  {
    name: $pgettext("column name", "Total"),
    slug: "total",
    sortable: true,
  },
  {
    name: $pgettext("column name", "Payment Method"),
    slug: "paymentType",
    sortable: false,
    selected: true,
    type: "money",
    selfBillingOnly: true,
    disallow: ["promoter"],
  },
  {
    name: $pgettext("column name", "Payment Status"),
    slug: "paymentStatus",
    sortable: false,
    selected: true,
    selfBillingOnly: true,
    disallow: ["promoter"],
  },
  {
    name: $pgettext("column name", "Minimum Payout Threshold"),
    slug: "minimumPayout",
    sortable: false,
    selected: true,
    selfBillingOnly: true,
    disallow: ["publisher", "promoter"],
  },
];

// For backward compatibility
export const COLUMNS = getColumns();

export const FILTER_TYPES = {
  "self billing": {
    advertiser: ["status", "documentType", "issueDate", "offer", "adInventory"],
    publisher: ["currency", "status", "documentType", "issueDate", "offer", "adInventory"],
    provider: [
      "currency",
      "recipient",
      "status",
      "documentType",
      "minimumPayout",
      "issueDate",
      "offer",
      "adInventory",
    ],
    square: [
      "currency",
      "sender",
      "recipient",
      "status",
      "documentType",
      "minimumPayout",
      "issueDate",
      "offer",
      "adInventory",
    ],
  },
  invoice: {
    advertiser: ["status", "documentType", "issueDate", "offer"],
    publisher: ["currency", "status", "documentType", "issueDate", "offer"],
    provider: ["currency", "recipient", "status", "documentType", "issueDate", "offer"],
    square: [
      "currency",
      "sender",
      "recipient",
      "status",
      "documentType",
      "issueDate",
      "offer",
    ],
  },
};

export const FILTER_CONFIG = {
  invoice: {
    api: {
      currency: "currencyForBillingOverview",
      status: "documentInvoiceStatus",
      documentType: "documentCorrection",
    },
    query: {
      status: "documentStatus",
      documentType: "type",
    },
  },
  "self billing": {
    api: {
      currency: "currencyForBillingOverview",
      status: "documentSelfBillingStatus",
      minimumPayout: "documentSelfBillingMinimumPayout",
      documentType: "documentCorrection",
    },
    query: {
      status: "documentStatus",
      documentType: "type",
    },
  },
};

export const ACTION_OPTIONS = [
  {
    name: $pgettext("column name", "Change status"),
    event: "change-status",
    expanded: false,
    icon: "change-status",
    disallow: ["publisher", "advertiser"],
    disabled: false,
    options: {
      invoice: [
        {
          name: $pgettext("column name", "Pro Forma"),
          disabled: false,
          id: "PRO_FORMA",
          event: "status-change",
          component: "VSVGStatusIcon",
          iconType: "documentStatus",
        },
        {
          name: $pgettext("column name", "Sent"),
          disabled: false,
          id: "SENT",
          event: "status-change",
          component: "VSVGStatusIcon",
          iconType: "documentStatus",
        },
        {
          name: $pgettext("column name", "Paid"),
          disabled: false,
          id: "PAID",
          event: "status-change",
          component: "VSVGStatusIcon",
          iconType: "documentStatus",
        },
        {
          name: $pgettext("column name", "Overdue"),
          disabled: false,
          id: "OVERDUE",
          event: "status-change",
          component: "VSVGStatusIcon",
          iconType: "documentStatus",
        },
        {
          name: $pgettext("column name", "Debt collection"),
          disabled: false,
          id: "DEBT_COLLECTION",
          event: "status-change",
          iconType: "documentStatus",
        },
        {
          name: $pgettext("column name", "Uncollectible"),
          disabled: false,
          id: "UNCOLLECTIBLE",
          event: "status-change",
          iconType: "documentStatus",
        },
      ],
      "self billing": [
        {
          name: $pgettext("column name", "Pro Forma"),
          disabled: false,
          id: "PRO_FORMA",
          event: "status-change",
          component: "VSVGStatusIcon",
          iconType: "documentStatus",
        },
        {
          name: $pgettext("column name", "Sent"),
          disabled: false,
          id: "SENT",
          event: "status-change",
          component: "VSVGStatusIcon",
          iconType: "documentStatus",
        },
        {
          name: $pgettext("column name", "Paid"),
          disabled: false,
          id: "PAID",
          event: "status-change",
          component: "VSVGStatusIcon",
          iconType: "documentStatus",
        },
      ],
    },
  },
  {
    name: $pgettext("column name", "Add correction"),
    event: "correct-document-new",
    icon: "correction",
    disallow: ["publisher", "advertiser"],
    disabled: false,
  },
  { divider: true, name: "1" },
  {
    name: $pgettext("column name", "Preview PDF"),
    event: "preview",
    icon: "preview",
    disabled: false,
  },
  {
    name: $pgettext("column name", "Send PDF to recipient"),
    event: "send",
    icon: "mail",
    disallow: ["publisher", "advertiser"],
    disabled: false,
  },
  {
    name: $pgettext("column name", "Download PDF"),
    event: "download",
    icon: "download",
    disabled: false,
  },
  { divider: true, name: "2" },
  {
    name: $pgettext("column name", "Copy ID"),
    event: "copy-id",
    icon: "duplicate",
    disabled: false,
  },
  {
    name: $pgettext("column name", "Delete"),
    event: "delete",
    icon: "delete",
    disallow: ["publisher", "advertiser"],
    disabled: false,
  },
];

export const STATUS_LIST = {
  invoice: [
    {
      name: $pgettext("column name", "Pro forma"),
      id: "PRO_FORMA",
      disabled: false,
      count: 0,
    },
    {
      name: $pgettext("column name", "Sent"),
      id: "SENT",
      disabled: false,
      count: 0,
    },
    {
      name: $pgettext("column name", "Paid"),
      id: "PAID",
      disabled: false,
      count: 0,
    },
    {
      name: $pgettext("column name", "Overdue"),
      id: "OVERDUE",
      disabled: false,
      count: 0,
    },
    {
      name: $pgettext("column name", "Debt collection"),
      id: "DEBT_COLLECTION",
      disabled: false,
      count: 0,
    },
    {
      name: $pgettext("column name", "Uncollectible"),
      id: "UNCOLLECTIBLE",
      disabled: false,
      count: 0,
    },
  ],
  "self billing": [
    {
      name: $pgettext("column name", "Pro forma"),
      id: "PRO_FORMA",
      disabled: false,
      count: 0,
    },
    {
      name: $pgettext("column name", "Sent"),
      id: "SENT",
      disabled: false,
      count: 0,
    },
    {
      name: $pgettext("column name", "Paid"),
      id: "PAID",
      disabled: false,
      count: 0,
    },
  ],
};

export const PRODUCT_LIST = [
  {
    id: "EXCHANGE",
    name: "Exchange",
  },
  {
    id: "CONVERT",
    name: "Convert",
  },
  { id: "FEES", name: "Fees" },
];

export const FEES_LIST = [
  { id: "ACCESS_FEE", name: "Access fee" },
  { id: "SETUP_FEE", name: "Setup fee" },
  { id: "FIXED_FEE", name: "Fixed fee" },
];

export const PAYMENT_METHOD_LIST = [
  {
    id: "BANK_TRANSFER",
    name: "Bank Transfer",
  },
  {
    id: "PAYPAL",
    name: "PayPal",
  },
];

export const STATUS_CHANGE_RULES = {
  [DS_PRO_FORMA]: [DS_SENT, DS_PAID],
  [DS_SENT]: [DS_OVERDUE, DS_DEBT_COLLECTION, DS_UNCOLLECTIBLE, DS_PAID],
  [DS_OVERDUE]: [DS_DEBT_COLLECTION, DS_UNCOLLECTIBLE, DS_PAID],
  [DS_DEBT_COLLECTION]: [DS_UNCOLLECTIBLE, DS_PAID],
  [DS_UNCOLLECTIBLE]: [DS_PAID],
  [DS_PAID]: [],
};

export const FINANCE_TRANSACTION_TYPES = [
  { id: "SALE", name: "Sales", iconId: 1 },
  { id: "LEAD", name: "Leads", iconId: 2 },
  { id: "INSTALL", name: "Installs", iconId: 3 },
  { id: "CLICK", name: "Clicks", iconId: 4 },
];
